export default {
  proxy_path: "https://mp5uezk077.execute-api.us-east-1.amazonaws.com/api",
  proxy_path_geojson_api: "https://4cqdlzlidl.execute-api.us-east-1.amazonaws.com/api",
  google_api_key: "AIzaSyCIWGqqG3v6U3gNYVZ7NjrcCPBVpx1pfgc",
  main_company_term_id: "6",
  crm_base_url: "staging.rossing.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.staging.rossing.forwoodsafety.com",
  MAP_REPORT_ACCESS_ROLE: "MapReportAccess",
  forwoodId: {
    URL: "https://id.staging.rossing.forwoodsafety.com",
    APP_CLIENT_ID: "46k8k2f51i1dd520sokm5ss3hp",
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://tesfyi0tw6.execute-api.us-east-1.amazonaws.com/prod",
  },
  reactApp: {
    HOSTNAME: "https://mapreport.staging.rossing.forwoodsafety.com",
  },
  get forwoodIdAuthUrl() {
    return `${
      this.forwoodId.URL
    }/authorize?response_type=token&scope=openid&client_id=${
      this.forwoodId.APP_CLIENT_ID
    }&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  },
};
